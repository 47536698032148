body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#full_name{
  color: #505050;
  font-size: 14px;
  font-family: Assistant;
  line-height: normal;
  width: 100%;
}

.MuiAutocomplete-paper .MuiAutocomplete-listbox li{
  line-height: 17px;
  height: 24px;
  font-size: 14px;
  font-family: Assistant;
}

.MuiAutocomplete-paper .MuiAutocomplete-listbox li:hover {
  background-color: rgba(18, 116, 228, 0.2);
}

.block_full_name > .MuiAutocomplete-root {
  width: 100%;
}

.block_full_name .MuiFormControl-root.MuiTextField-root{
  width:100%;
}

.wrapperAutocompleteOption{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option{
  width: 100%;
  display: flex;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option > div{
  width: 100%;
}

.wrapperAutocompleteOption .right-new{
  color: #1274E4;
  width: 100px;
  padding-right: 10px;
}

.wrapperAutocompleteOption .left-new{
  width:100%;
}

.block_full_name{
  display: flex;
  justify-content: space-between;
}

.block_full_name .MuiInputLabel-formControl{
  font-size: 14px;
  font-family: Assistant;
  line-height: normal;
}

.contacts_page th{
  min-width: 160px;
}

.contacts_page .MuiPaper-rounded > div{
  overflow: visible;
}

.button-m-top{
  margin-top: 10px;
}

.arrowAnimation {
  position: absolute;
  left: calc(50% - 25px);
  bottom: 20px;
  width: 50px;
  animation: arrowDown 2s infinite;
  text-align: center;
}

.arrowAnimation.arrow2{
  opacity: 0;
  animation-delay: 1s;
}

@keyframes arrowDown{
  0%{
    bottom: 20px;
    opacity: 1;
  }
  100%{
    bottom: 0px;
    opacity: 0;
  }
}

#qBase{
  position: relative;
}

/* always show scrollbars */

#qBase::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  border: 1px solid rgb(170, 215, 250);
}

#qBase::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgb(170, 215, 250);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.mapWizardScroll::-webkit-scrollbar {
  width: 10px;
  border-radius: 0;
  background-color: rgb(170, 215, 250);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
.mapWizardScroll::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 0;
  background-color: rgb(170, 215, 250);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.mapWizardScroll {
  max-height: 520px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -14px;
  padding-right: 14px;
  position: relative;
}

.mapWizardScroll > div:last-child > div:first-child + div {
  display: none;
}

div[role="presentation"] > div::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  border: 1px solid rgb(170, 215, 250);
}

div[role="presentation"] > div::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgb(170, 215, 250);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

div[role="presentation"] > div ul > li {
  padding-right: 25px;
  width: 100%;
  box-sizing: border-box;
}

div[role="presentation"] > div ul{
  width: 100%;
  display: block;
  padding-right: 4px;
  box-sizing: border-box;
  min-width: 250px;
}

.selectStyleForStatistics .MuiInput-underline:before{
  display: none;
}

.login-page {
  margin-bottom: 30px!important;
  margin-top: 20px!important;
}

.logo-align{
  align-items: flex-start;
  text-align: left;
  display: flex;
  width: 100%;
}

.login-page svg{
  width: 60px;
  height: 60px;
}

.login-page p{
  line-height: 60px;
  font-size: 24px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 140px;
  height: 140px;
  position: absolute;
  top: calc(50vh - 70px);
  left: calc(50% - 70px);
  z-index: 999999;
}

.load .loader{
  width: 140px;
  height: 140px;
  border:solid 6px rgb(170, 215, 250);
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 2s ease-in;
  -webkit-animation-name:             rotate;
  -webkit-animation-duration:         2.0s;
  -webkit-animation-iteration-count:  infinite;
  -webkit-animation-timing-function: linear;
  transition: all 2s ease-in;
  animation-name:             rotate;
  animation-duration:        2.0s;
  animation-iteration-count:  infinite;
  animation-timing-function: linear;
}

.load .logo {;
  display: flex;
  top: calc(50% - 6px);
  left: 22px;
  align-items: center;
  text-align: center;
  position: absolute;
}

.load .logo {
  width: 100px;
  height: 24px;
  -webkit-animation: pulsing 3s infinite;
  animation: pulsing 3s infinite;
}

@-webkit-keyframes pulsing {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes pulsing {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}